import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchPropertyOfferDetails= createAsyncThunk(
  'getPropertyOfferService/fetchPropertyOfferDetails',
  async (params) => {
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/propertymanagementofferdetails/'}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const getPropertyOfferServiceSlice = createSlice({
  name: 'getPropertyOfferService',
  initialState: {
    getPropertyOfferService: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPropertyOfferDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
        fetchPropertyOfferDetails.fulfilled,
      (state, action) => {
        state.getPropertyOfferService = action.payload.data;
        state.loading = false;
      }
    );
    builder.addCase(fetchPropertyOfferDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default getPropertyOfferServiceSlice.reducer;
