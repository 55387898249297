// // AuthGuard.js
// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';

// const AuthGuard = ({ children }) => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const isAuthenticated = localStorage.getItem('is_login') === 'true';
//     if (!isAuthenticated) {
//       navigate('/login');
//     }
//   }, [navigate]);

//   return <>{children}</>;
// };

// AuthGuard.propTypes = {
//   children: PropTypes.object.isRequired,
// };

// export default AuthGuard;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const isAuthenticated = localStorage.getItem('is_login') === 'true';
      const is_password_valid = localStorage.getItem("is_password_valid")==true;
      console.log("is_password_valid",is_password_valid);
      
      if (!isAuthenticated ) {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error accessing localStorage:', error);
      navigate('/login'); 
    }
  }, [navigate]);

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
