// import React, { useEffect, useRef, useState } from 'react';
// import Alertpopup from './Alert';

// const InactivityAlert = () => {
//   const [showAlert, setShowAlert] = useState(false);
//   const timeoutRef = useRef(null);

//   const resetTimer = () => {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//     timeoutRef.current = setTimeout(() => {
//       setShowAlert(true); // Show the custom alert
//     }, 600000); // 5 minutes in milliseconds
//   };

//   useEffect(() => {
//     window.addEventListener('mousemove', resetTimer);
//     window.addEventListener('keydown', resetTimer);

//     resetTimer();

//     return () => {
//       window.removeEventListener('mousemove', resetTimer);
//       window.removeEventListener('keydown', resetTimer);
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//     };
//   }, []);

//   const handleCloseAlert = () => {
//     setShowAlert(false);
//     resetTimer();
//   };

//   return (
//     <>
//       <Alertpopup
//         notification="System is inactive! No activity detected in the last 5 minutes."
//         visible={showAlert}
//         setState={setShowAlert} // Pass the setShowAlert function
//       />
//     </>
//   );
// };

// export default InactivityAlert;

import React, { useEffect, useRef, useState } from 'react';
import Alertpopup from './Alert';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from 'src/utils/constant';
import { resetuserloginpage } from 'src/Store/Slicer/userLoginSlicer';
import { useDispatch } from 'react-redux';

const InactivityAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const timeoutRef = useRef(null);
  const logoutTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem('is_login') === 'true';

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setShowAlert(true);
      logoutTimeoutRef.current = setTimeout(() => {
        handleLogout();
      }, 1200000);
    }, 600000);
  };

  const handleLogout = () => {
    if (isLoggedOut) return;
    setIsLoggedOut(true);
    localStorage.removeItem('token_key');
    localStorage.removeItem('is_login');
    localStorage.removeItem('is_role');
    // localStorage.clear();
    dispatch(resetuserloginpage());
    navigate(LOGIN);
  };

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    resetTimer();

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (logoutTimeoutRef.current) {
        clearTimeout(logoutTimeoutRef.current);
      }
    };
  }, []);

  const handleCloseAlert = () => {
    setShowAlert(false);
    resetTimer();
    setIsLoggedOut(false);
  };

  return (
    <>
    {isAuthenticated&&
      <Alertpopup
        notification="10 minutes idle. You’ll be logged out in 20 minutes if inactive."
        visible={showAlert}
        setState={setShowAlert}
        onClose={handleCloseAlert}
      />}
    </>
  );
};

export default InactivityAlert;
