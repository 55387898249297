import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchPropertyPriceListDetails = createAsyncThunk(
    'getPropertyPriceListService/fetchPropertyPriceListDetails',
    async (params) => {
        const option = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token_key'),
            },
        };
        const url = `${API_URL + 'api/v1/propertypricelistmaster/'}`;
        const response = await callFetch(url, option);
        const value = await response;
        return value;
    }
);

const getPropertyPriceListServiceSlice = createSlice({
    name: 'getPropertyPriceListService',
    initialState: {
        getPropertyPriceListService: [],
        loading: false,
        nextPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPropertyPriceListDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchPropertyPriceListDetails.fulfilled,
            (state, action) => {
                state.getPropertyPriceListService = action.payload.data;
                state.loading = false;
            }
        );
        builder.addCase(fetchPropertyPriceListDetails.rejected, (state) => {
            state.loading = false;
        });
    },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default getPropertyPriceListServiceSlice.reducer;
